









































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Mutation } from 'vuex-class'
import { ElMessageBoxOptions } from 'element-ui/types/message-box'
import ManageTable from '@/components/shared/ManageTable.vue'
import { recipientRuleDescriptor, registerRecipientOptions } from '@/data/RegisterRecipientFormData'
import {
  IRegisterRecipientPresentation,
  RegisterRecipientPresentation
} from '@/presentation/recipient/RegisterRecipientPresentation'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

@Component({
  components: { ManageTable }
})
export default class Register extends Vue {
  @Getter getValidatedRecipients: any
  @Mutation enableLoading: any
  @Mutation disableLoading: any

  presentation: IRegisterRecipientPresentation = new RegisterRecipientPresentation()
  isPresentationReady:boolean = false
  tableOption: Dictionary<ManageTableOptions> = registerRecipientOptions
  descriptor: Dictionary<any> = recipientRuleDescriptor

  async register (): Promise<void> {
    if (!this.presentation.hasUpdatingRecipients) {
      this.enableLoading(this.$t('notification.loading.upload_excel'))
      const isFailUpload = await this.presentation.register()
      if (isFailUpload) {
        const alertOption: ElMessageBoxOptions = {
          confirmButtonText: 'OK',
          center: true,
          callback: () => {
            this.disableLoading()
          }
        }
        this.$alert('업로드에 실패했습니다.', 'Error', alertOption)
      } else {
        this.disableLoading()
        this.$message({ type: 'success', message: `${this.$t('notification.registered')}` })
        this.$router.push('/recipient/recipients')
      }
    }
  }

  getCountryMethodLabel (countryWithMethod: string): string {
    return this.presentation.getCountryMethodLabel(countryWithMethod)
  }

  handleTabClick (): void {
    this.presentation.page = 1
  }

  deleteRow (countryWithMethod: string, index: number): void {
    this.presentation.deleteRow(countryWithMethod, index)
  }

  toggleUpdatable (countryWithMethod: string, index: number, callback: any): void {
    this.presentation.toggleUpdatable(countryWithMethod, index, callback)
  }

  created () {
    const recipients = this.getValidatedRecipients
    this.presentation.initialize(recipients)
    if (this.presentation.isEmptyRecipients) {
      this.$router.push('/recipient/enlist')
      return
    }
    this.isPresentationReady = true
  }
}
